import {
  PaxTypeOld
} from 'be-structures/typescript-generator/assembly'

import {
  IProductDetailsPaxComplectClass,
  IPaxComplectWithPriceResource,
  IPaxComplect,
  convertIPaxComplectWithPriceResourceToIPaxComplect,
  setClassName
} from 'modules/product-result.v2/data/package-result/package-result.details-complect/package-result.details-complect.types'

export class ProductDetailsPaxComplect
  implements IProductDetailsPaxComplectClass {
  private _paxComplect: IPaxComplect = null
  
  constructor(paxComplect?: IPaxComplectWithPriceResource) {
    setClassName(ProductDetailsPaxComplect.name, this)
    
    this._paxComplect = convertIPaxComplectWithPriceResourceToIPaxComplect({ paxComplect })
  }

  get content() {
    return this._paxComplect
  }

  get paxList(): PaxTypeOld[] {
    const paxList: PaxTypeOld[] = []

    this._paxComplect.price.paxPrices?.map(paxPrice => {
      for (let i = 0; i < paxPrice.quantity; i++) {
        paxList.push(paxPrice.paxType)
      }
    })

    return paxList
  }

  get roomType(): string {
    return this._paxComplect.roomType
  }

  get roomRemark(): string {
    return this._paxComplect.remark
  }

  get roomId(): string {
    return this._paxComplect.entityId
  }

  get price() {
    return this._paxComplect.price
  }

  get originalPrice() {
    return this._paxComplect.originalPrice
  }

  set roomId(id: string) {
    this._paxComplect.entityId = id
  }
}
