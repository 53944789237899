import {
  IPaxComplect
} from 'modules/product-result.v2/data/product-result.types';

import { PackageProductPrice } from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.class'

export const PAX_COMPLECT_BLANK: IPaxComplect = {
  // id: null,
  entityId: null,
  roomType: null,
  bookingCode: null,
  remark: null,
  price: new PackageProductPrice(),
  originalPrice: new PackageProductPrice(),
}